import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import MetaComponents from "../components/seo/MetaComponents";
import Article from "../components/Article";
import ArticleHeader from "../components/ArticleHeader";

const Agb = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Miracl-Plattform - Allgemeine Bestimmungen und Nutzungsbedingungen" />
            <Article>
                <h2>1 Allgemeines, Vertragsgegenstand</h2>
                <p>
                    1.1 Die Miracl GmbH (“<strong>Betreiber</strong>“) bietet Nutzern, die an dem Erwerb einer Immobilie
                    interessiert sind (“<strong>Nutzer</strong>“) die Miracl-Plattform (“
                    <strong>Plattform</strong>“) an, die die Nutzer bei dem Erwerb der Immobilie unterstützt werden.
                </p>
                <p>1.2 Neben dem Zugang zur Plattform bietet der Betreiber folgende Leistungen an:</p>
                <ul>
                    <li>
                        Vermittlung von Krediten zwischen Bank- oder Kreditinstituten einerseits und den Nutzern
                        (potenziellen Kreditnehmern) andererseits (
                        <strong>
                            für diese Leistungen gelten zusätzlich die in Anlage A genannten Bedingungen
                            (AGB-Kreditvermittler); bei Widerspruch haben die Bestimmungen der Anlage A Vorrang
                        </strong>
                        );
                    </li>
                </ul>
                <ul>
                    <li>
                        Vermittlung von Kreditvermittlern / Einholung von Finanzierungsangeboten und Weiterleitung an
                        den Kunden;
                    </li>
                </ul>
                <ul>
                    <li>
                        Vermittlung von Rechtsanwälten zur Abwicklung der Kaufvertragserrichtung, Treuhandschaft und
                        grundbücherlichen Durchführung.
                    </li>
                </ul>
                <p>
                    Der Zugang und die genannten Vermittlungsleistungen werden als “<strong>Plattformleistungen</strong>
                    ” bezeichnet.
                </p>
                <p>1.3 Beauftragung und Abwicklung von Dienstleistungen dritter Dienstleister</p>
                <p>
                    Die oben genannten Dritten (das sind die genannten Sachverständige, Kreditvermittler und
                    Rechtsanwälte; “<strong>Dienstleister</strong>“) werden dem Nutzer von der Plattform genannt.
                    Wünscht der Nutzer die Abwicklung des Kaufs der Immobilie über die Plattform zu den von der
                    Plattform genannten Kosten, muss sich der Nutzer dieser Dritter durch Verwendung der von der
                    Plattform vorgegeben standardisierten Prozesse bedienen und die oben beschriebenen Dienstleistungen
                    bei diesen Dienstleistern beauftragen (“<strong>Drittdienstleistungen</strong>“). Die weitere
                    Beauftragung und Abwicklung der Drittdienstleistungen erfolgt dann zwischen den Nutzern und dem
                    jeweiligen Dienstleister.{" "}
                    <strong>
                        Der Dienstleistungsvertrag kommt daher zwischen den Nutzern und dem jeweiligen Dienstleister
                        direkt zustande.
                    </strong>{" "}
                    Für die Leistungserbringung durch diese Dienstleister gelten die Geschäftsbedingungen des jeweiligen
                    Dienstleisters (siehe Anlage B).
                    <strong>
                        {" "}
                        Auf diese Leistungen hat der Betreiber keinen Einfluss, haftet nicht für die jeweilige
                        Leistungserbringung und übernimmt auch keine Verpflichtung, diese zu überwachen bzw für die
                        Einhaltung der vom jeweiligen Dienstleister übernommenen Verpflichtungen zu sorgen.
                    </strong>{" "}
                    Die Verantwortung für die Drittdienstleistungen liegt beim jeweiligen Dienstleister.
                </p>
                <p>
                    1.4{" "}
                    <strong>
                        Der Betreiber wird weder Vertragspartei noch sonstiger Beteiligter an dem Vertragsverhältnis
                        zwischen dem Dritten und den Nutzern
                    </strong>
                    .{" "}
                    <strong>
                        Diesbezüglich übernimmt der Betreiber keine wie immer geartete Verantwortung oder Haftung
                    </strong>
                    . Der Betreiber stellt ausschließlich die Plattform zur Verfügung und vermittelt die
                    Dienstleistungen der Dienstleister. Er haftet für deren Auswahl jedoch nach den Bestimmungen des
                    §&nbsp;1315 ABGB.
                </p>
                <h2>
                    <strong>2 Geltung der Nutzungsbedingungen</strong>
                </h2>
                <p>
                    Die Nutzung der Plattform und die vom Betreiber angebotenen Plattformleistungen unterliegen diesen
                    Nutzungsbedingungen. Diese Nutzungsbedingungen regeln lediglich das Verhältnis zwischen den Nutzern
                    und Miracl GmbH als Betreiber der Plattform. Wenn die Nutzer diesen Bedingungen nicht zustimmen,
                    sind sie nicht berechtigt, diese Plattform zu verwenden.
                </p>
                <h2>
                    <strong>3 Verwendung der Plattform</strong>
                </h2>
                <p>
                    3.1 Eine Nutzung der Plattform ist nur erlaubt, wenn die Nutzer für den Abschluss dieses Vertrages
                    geschäftsfähig und zumindest 18 Jahre alt sind.
                </p>
                <p>
                    3.2 Um die Plattform nutzen zu können, können sich die Nutzer registrieren (dazu siehe unten Punkt
                    4). Die Plattform kann aber auch ohne Registrierung genutzt werden.
                </p>
                <h2>
                    <strong>4 Registrierung</strong>
                </h2>
                <p>
                    4.2 Eine Registrierung erfolgt unter Angabe der zwingend erforderlichen nachfolgenden Daten: Name,
                    Geburtsdatum und Geschlecht, E-Mail-Adresse, Telefonnummer und Passwort. Nach Bestätigung der
                    Nutzungsbedingungen mittels Checkbox wird das Profil der Nutzer im System angelegt.
                </p>
                <p>
                    4.3 Die Nutzer sind dafür verantwortlich, dass die im Registrierungsprozess angegebenen Daten
                    zutreffend sind. Die Registrierung ist nur im eigenen Namen erlaubt. Die Weitergabe des Passworts an
                    Dritte oder die Nutzung durch Dritte ist ebenfalls untersagt. Die Nutzer haften dafür, dass sie Ihr
                    Passwort sicher verwahren und geheim halten.
                </p>
                <p>
                    4.4 Der Betreiber haftet nicht für Verluste oder Schäden, die entstehen, weil die Nutzer
                    unzutreffende Informationen übermitteln oder ihre Kontoinformationen nicht schützen. Bei Missbrauch
                    oder Verdacht auf Missbrauch der Kontoinformationen müssen die Nutzer den Betreiber unter folgender
                    E-Mail-Adresse benachrichtigen: [<a href="mailto:kontakt@miracl.at">kontakt@miracl.at</a>].
                </p>
                <h2>
                    <strong>5 Vertragsdauer</strong>
                </h2>
                <p>
                    5.1 Der Vertrag über die Nutzung der Plattform beginnt bei Registrierung mit der Registrierung | bei
                    Nicht-Registrierung mit Ausfüllung des Fragenkatalogs und wird auf unbestimmte Zeit abgeschlossen.
                </p>
                <p>
                    5.2 Der Betreiber ist berechtigt auch ohne vorherige Benachrichtigung, den Zugriff auf die Plattform
                    und das Konto vorübergehend auszusetzen, wenn begründeter Verdacht besteht, dass die Nutzer oder ein
                    Dritter, der die Kontoinformationen der Nutzer benutzt, gegen diese Bedingungen oder gegen geltendes
                    Recht im Zusammenhang mit der Nutzung dieser Plattform verstößt oder wenn technische Umstände oder
                    Sicherheitsprobleme dies notwendig machen. Der Betreiber wird die Nutzer verständigen, wenn der
                    begründete Verdacht eines Verstoßes gegen diese Bedingungen oder geltendes Recht vorliegt und den
                    Nutzern diesbezüglich Möglichkeit zur Rechtfertigung und Aufklärung geben. Sollte sich der Verdacht
                    nicht ausräumen lassen, so ist der Betreiber berechtigt, das Konto der Nutzer unwiderruflich zu
                    schließen, wenn der Betreiber dies im Hinblick auf die Schwere des Verstoßes für angemessen hält.
                </p>
                <p>
                    5.3 Der Betreiber kann den Betrieb der Plattform einstellen oder auch nur das Nutzungsrecht der
                    Nutzer kündigen. Beides ist jederzeit und ohne Angabe von Gründen möglich. Der Betreiber wird den
                    Nutzern einen solchen Schritt jedoch mit angemessener Vorankündigungsfrist mitteilen und die
                    Möglichkeit geben, entsprechende Vorkehrungen zu treffen, dass den Nutzern daraus kein
                    unrechtmäßiger Schaden entsteht. Dies gilt nicht für bereits abgeschlossene und im
                    Abwicklungsstadium befindliche Verträge. In diesem Fall wird der Betreiber die Nutzer weiterhin
                    unterstützen.
                </p>
                <h2>
                    <strong>6 Nutzungsrechte</strong>
                </h2>
                <p>
                    6.1 Den Nutzern stehen ausschließlich die nach diesen Nutzungsbedingungen eingeräumten Rechte an der
                    Plattform.
                </p>
                <p>
                    6.2 Die Nutzer verpflichten sich, die Dienste nur unter Einhaltung der vertraglichen und
                    gesetzlichen Bestimmungen in Anspruch zu nehmen und jedwede missbräuchliche Inanspruchnahme zu
                    unterlassen.
                </p>
                <h2>
                    <strong>7 Gesetzliche Widerrufsbelehrung</strong>
                </h2>
                <p>7.1 Widerrufsrecht</p>
                <p>
                    Die Nutzer haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
                    widerrufen.
                </p>
                <p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.</p>
                <p>
                    Um das Widerrufsrecht auszuüben, müssen die Nutzer den Betreiber [Miracl GmbH, Lindengasse 26/1/5,
                    1070 Wien und,{" "}
                    <em>
                        <a href="mailto:kontakt@miracl.at">kontakt@miracl.at</a>
                    </em>
                    ], mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail)
                    über den Entschluss, diesen Vertrag zu widerrufen, informieren. Die Nutzer können dafür das
                    beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
                </p>
                <p>
                    Zur Wahrung der Widerrufsfrist reicht es aus, dass die Nutzer die Mitteilung über die Ausübung des
                    Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                </p>
                <p>7.2 Folgen des Widerrufs</p>
                <p>
                    Wenn die Nutzer diesen Vertrag widerrufen, wird der Betreiber den Nutzer-Account und die zur
                    Verfügung gestellte Daten umgehend löschen.
                </p>
                <p>7.3 Muster-Widerrufsformular:</p>
                <p>
                    Wenn die Nutzer den Vertrag widerrufen wollen, dann können sie auch dieses Formular ausfüllen und
                    dem Betreiber senden:
                </p>
                <p>An Miracl GmbH, Lindengasse 26/1/5, 1070 Wien, [kontakt@miracl.at]:</p>
                <p>
                    Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über die Erbringung der
                    folgenden Dienstleistung: <strong>Nutzung der Plattform | Plattformleistungen</strong>
                </p>
                <p>Registriert am (*)</p>
                <p>Name des/der Verbraucher(s)</p>
                <p>Anschrift des/der Verbraucher(s)</p>
                <p>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
                <p>Datum</p>
                <p>(*) Unzutreffendes streichen.</p>
                <h2>
                    <strong>8 Zugriff auf die Plattform</strong>
                </h2>
                <p>
                    8.1 Aufgrund der Beschaffenheit des Internets kann der Betreiber nicht die durchgehende und
                    ununterbrochene Verfügbarkeit und Erreichbarkeit der Plattform zusagen. Der Betreiber kann die
                    Verfügbarkeit der Plattform oder bestimmte Bereiche oder Funktionen der Plattform jederzeit ohne
                    Vorankündigung einschränken, insbesondere auch dann, wenn dies im Hinblick auf Kapazitätsgrenzen,
                    die Sicherheit oder Integrität der Server oder zur Durchführung von Wartungsmaßnahmen, die die
                    ordnungsgemäße oder verbesserte Funktion der Plattform gewährleisten, notwendig ist.
                </p>
                <p>
                    8.2 Der Betreiber kann die Plattform verbessern, weiterentwickeln und verändern und von Zeit zu Zeit
                    neue Dienste einführen.
                </p>
                <p>
                    8.3 Der Betreiber ist berechtigt, jederzeit nach eigenem Ermessen neue Elemente als Bestandteil
                    und/oder als Ergänzung der Plattform zu implementieren sowie die Art der Nutzbarkeit der Plattform
                    auch grundlegend zu verändern. Der Betreiber ist auch berechtigt, die Plattform zur Gänze
                    abzuschalten und den Service einzustellen. Bevor der Betreiber die Plattform abschaltet und den
                    Service einstellt, wird der Betreiber die Nutzer mit angemessener Vorlaufzeit unter der bekannt
                    gegebenen E-Mail-Adresse diesbezüglich kontaktieren und mitteilen, ab wann die Plattform nicht mehr
                    verfügbar sein wird. Nach Ablauf dieser Frist können die Nutzer auf die Plattform nicht mehr
                    zugreifen. Der Betreiber wird die Nutzer bei bereits im Abwicklungsstadium befindlichen Verträgen
                    weiterhin unterstützen.
                </p>
                <h2>
                    <strong>9 Änderungen der Bedingungen</strong>
                </h2>
                <p>
                    Aufgrund von Änderungen auf der Plattform und der geltenden Gesetze oder aus sonstigen betrieblichen
                    Gründen wird es immer wieder die Notwendigkeit geben, diese Bedingungen anzupassen. Geänderte
                    Bedingungen wir den Nutzern auf der Plattform bekanntgeben. Sollten die Nutzer diesen Bedingungen
                    nicht zustimmen, sind sie nicht berechtigt, die Plattform weiter zu verwenden. Bereits
                    abgeschlossene und im Abwicklungsstadium befindliche Verträge werden zu den zum Zeitpunkt des
                    Vertragsabschlusses gültigen Bedingungen fortgesetzt.
                </p>
                <h2>
                    <strong>10 Gewährleistung und Haftung</strong>
                </h2>
                <p>
                    Der Betreiber stellt die Plattform <strong>kostenlos</strong> “wie gesehen” zur Verfügung. Die
                    Nutzer bestätigen ausdrücklich, folgende Bestimmungen zu verstehen und mit ihnen einverstanden zu
                    sein:
                </p>
                <p>
                    10.1 Der Betreiber übernimmt für die durch die Plattform generierten Informationen, Dienstleistungen
                    und Services keine Gewähr auf Richtigkeit, Aktualität, Vollständigkeit und Funktionalität.
                </p>
                <p>
                    10.2 Es ist nicht Teil der Leistung, dass (i) die Plattform jederzeit abrufbar, sicher oder
                    fehlerfrei ist, oder (ii) unwesentliche Fehler in der Software oder im Service behoben werden.
                    Diesbezüglich schließt der Betreiber daher jede Gewährleistung aus.
                </p>
                <p>
                    10.3 Die Nutzer nehmen zur Kenntnis, dass die vom Betreiber angebotenen Dienste auch unter
                    Einbeziehung dritter Netzbetreiber angeboten werden. Die Verfügbarkeit der Dienste ist deshalb von
                    der technischen Bereitstellung fremder Dienste abhängig, auf die der Betreiber keinen Einfluss hat.
                    Der Betreiber haftet daher nicht für eine allfällige Unterbrechung, Störung oder Fehlerübertragung
                    im Zusammenhang mit der Bereitstellung des kostenlosen Services.
                </p>
                <p>
                    10.4 Der Betreiber ist berechtigt, die Dienste aus internen Gründen, etwa zu Wartungszwecken, für
                    eine angemessene Zeit zu unterbrechen. Die Nutzer können daraus keine Ansprüche ableiten.
                </p>
                <p>
                    10.5 Die Drittdienstleistungen werden von Dritten (den Dienstleistern) direkt angeboten und werden
                    von dieser kontrolliert und zur Verfügung gestellt. Die Verantwortung für diese Dienstleistungen
                    liegt beim jeweiligen Dienstleister.
                </p>
                <p>
                    10.6 Der Betreiber garantiert keinen Anspruch des Nutzers auf den tatsächlichen Abschluss eines
                    entsprechenden Vertrages mit dem Anbieter der Immobilie.
                </p>
                <p>
                    10.7 <strong>Haftungsbeschränkung</strong>
                </p>
                <p>
                    Es gelten die zwingenden Bestimmungen des Konsumentenschutzgesetzes sowie nachfolgende
                    Haftungsbeschränkung:
                </p>
                <p>
                    <strong>
                        Der Betreiber schließt seine Haftung für Schäden, Verluste, Kosten oder andere Nachteile aus,
                        die mit nur leichter Fahrlässigkeit verschuldet wurden. Dieser Haftungsausschluss gilt nicht für
                        Personenschäden.
                    </strong>
                </p>
                <h2>
                    <strong>11 Kontaktinformationen</strong>
                </h2>
                <p>Die Kontaktinformationen sind:</p>
                <p>Miracl GmbH</p>
                <p>Lindengasse 26/1/5, 1070 Wien</p>
                <p>Firmenbuchnummer FN 561504 t</p>
                <p>
                    [<a href="mailto:kontakt@miracl.at">kontakt@miracl.at</a>]
                </p>
                <h2>
                    <strong>12 Informationen für Verbraucher</strong>
                </h2>
                <p>
                    12.1 Ein außergerichtliches Beschwerde- oder Rechtsbehelfsverfahren, dem der Betreiber unterworfen
                    sind, gibt es nicht.
                </p>
                <p>12.2 Der Betreiber hat sich keinem besonderen Verhaltenskodex (Regelwerk) unterworfen</p>
                <p>
                    12.3 Die Nutzer können den Text der Nutzungsbedingungen jederzeit in der Plattform unter [•]
                    abrufen, ausdrucken und abspeichern.
                </p>
                <p>12.4 Die für den Vertragsabschluss zur Verfügung stehende Sprache ist ausschließlich Deutsch.</p>
                <h2>
                    <strong>13 Schlussbestimmungen</strong>
                </h2>
                <p>
                    13.1 Diese Bedingungen und die Geschäftsbeziehung zwischen den Nutzern und dem Betreiber,
                    einschließlich etwaiger Fragen über das gültige Zustandekommen eines Vertragsverhältnisses und
                    einschließlich etwaiger Streitigkeiten unterliegen den Gesetzen der Republik Österreich unter
                    Ausschluss des internationalen Privatrechts und Kollisionsnormen. Diese Rechtswahl führt nicht dazu,
                    dass den Nutzern der Schutz entzogen wird, der ihnen durch diejenigen Bestimmungen gewährt wird, von
                    denen nach dem Recht des Staates, in dem sie Ihren gewöhnlichen Aufenthalt haben, nicht durch
                    Vereinbarung abgewichen werden darf (<em>Günstigkeitsprinzip</em>).
                </p>
                <p>
                    13.2 Sollten einzelne Bestimmungen dieser Bedingungen einschließlich dieser Bestimmung ganz oder
                    teilweise unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Regelungen unberührt.
                </p>
                <p>
                    <strong>Stand</strong> Juli 2021
                </p>
                <h3>
                    <strong>Anlage A – AGB-Kreditvermittler</strong>
                </h3>
                <h2>Allgemeine Geschäftsbedingungen für die Kreditvermittlung</h2>
                <h3>
                    <strong>§ 1 Geltungsbereich</strong>
                </h3>
                <p>
                    Soweit nicht ausdrücklich Gegenteiliges vereinbart wurde, gelten unsere, dem Vertragspartner bekannt
                    gegebenen AGB. Zum Geltungsbereich dieser Allgemeinen Geschäftsbedingungen gehören insbesondere die
                    Dienstleistung der Vermittlung von Personalkrediten, Hypothekarkrediten und Finanzierungen gem §
                    136a Abs 1 Z 2 GewO. Im Hinblick auf die genannten Dienstleistungen ergänzen diese Allgemeinen
                    Geschäftsbedingungen für die Kreditvermittlung die Allgemeinen Geschäftsbedingungen des
                    Finanzdienstleisters. Für Fragen, die in den Allgemeinen Geschäftsbedingungen für die
                    Kreditvermittlung nicht geregelt sind, gelten die Regelungen der Allgemeinen Geschäftsbedingungen
                    des Finanzdienstleisters.
                </p>
                <p>
                    Genderhinweis: Aus Gründen der besseren Lesbarkeit wird in den Besonderen Geschäftsbedingungen für
                    die Kreditvermittlung auf eine geschlechtsneutrale Differenzierung (z.B. Kreditvermittler/
                    Kreditvermittlerin) verzichtet. Die verkürzte Sprachform hat ausschließlich redaktionelle Gründe und
                    beinhaltet keine Wertung.
                </p>
                <h3>
                    <strong>§ 2 Vermittlung und Beratung</strong>
                </h3>
                <p>
                    Die <strong>Tätigkeit des Kreditvermittlers</strong> besteht darin, dem Kunden
                </p>
                <ol>
                    <li>Kreditverträge oder sonstige Kreditierungen vorzustellen oder anzubieten,</li>
                    <li>
                        bei anderen als den in Z 1 genannten Vorarbeiten oder anderen vorvertraglichen administrativen
                        Tätigkeiten zum Abschluss von Kreditverträgen oder sonstigen Kreditierungen behilflich zu sein,
                        oder
                    </li>
                    <li>
                        für den Kreditgeber Kreditverträge abzuschließen oder bei sonstigen Kreditierungen für den
                        Kreditgeber zu handeln.
                    </li>
                </ol>
                <p>
                    Unter <strong>Beratungsdienstleistungen</strong> ist die Erteilung individueller Empfehlungen an den
                    Kunden in Bezug auf ein oder mehrere Geschäfte im Zusammenhang mit Kreditverträgen zu verstehen.
                    Solche Beratungsdienstleistungen sind in der Kreditvermittlung nicht umfasst und müssen gesondert
                    vereinbart werden. Bietet ein Kreditvermittler solche Beratungsdienstleistungen an, wird er den
                    Kunden darüber, sowie über die Konditionen, gesondert informieren.
                </p>
                <h3>
                    <strong>§ 3 Informationspflichten des Kunden</strong>
                </h3>
                <p>
                    Zur Abwicklung der Kreditanfrage benötigt der Kreditvermittler eine Reihe von Informationen vom
                    Kunden. Der Kunde verpflichtet sich, die vom Kreditvermittler bei ihm angeforderten Informationen
                    und Unterlagen unverzüglich zu übermitteln.
                </p>
                <p>
                    Der Kunde ist verpflichtet, dies dem Kreditvermittler mitzuteilen, wenn er bereits bei einer anderen
                    Stelle ein Kreditansuchen gestellt hat. Weiters hat es der Kunde dem Kreditvermittler mitzuteilen,
                    wenn ein von ihm gestelltes Kreditansuchen, aus welchem Grund auch immer, abgelehnt worden ist.
                </p>
                <p>
                    Der Kunde nimmt zur Kenntnis, dass unrichtige und unvollständige Informationen dazu führen können,
                    dass sein Kreditansuchen nicht erfolgreich ist. Für den Fall, dass der Kunde durch schuldhafte
                    Fehlinformationen das Scheitern der Vermittlung herbeigeführt hat, ist der Kunde dem
                    Kreditvermittler zum Schadenersatz, insbesondere zum Ersatz der entgangenen Vergütung, verpflichtet.
                </p>
                <h3>
                    <strong>§ 4 Datenschutz, Bankgeheimnis</strong>
                </h3>
                <p>
                    Sofern der Kunde dem Kreditvermittler per Telefon, Fax, Post oder E-Mail seine Daten bekannt gegeben
                    hat, verarbeitet der Kreditvermittler diese Daten auf der Rechtsgrundlage Art 6 Abs 1 lit b DSGVO
                    (Durchführung vorvertraglicher Maßnahmen auf Kundenanfrage hin und Vertragserfüllung), um die
                    Anfrage des Kunden zu bearbeiten und verarbeitet diese weiter, falls nachfolgend ein
                    Vertragsverhältnis zustande kommt. Wenn der Kunde seine Daten dazu nicht bereitstellt, kann dessen
                    Anfrage nicht bearbeitet werden und folglich auch kein nachfolgendes Vertragsverhältnis zustande
                    kommen. Der Kreditvermittler ist für die Verarbeitung der Daten seiner Kunden gemäß der
                    Datenschutz-Grundverordnung (DSGVO) verantwortlich und wird diese Daten gemäß den Bestimmungen des
                    in Österreich geltenden Datenschutzrechts und sohin insbesondere nach der DSGVO, dem
                    Datenschutzgesetz (DSG) und dem Telekommunikationsgesetz (TKG) verarbeiten.
                </p>
                <p>
                    Für die Zwecke der Kreditvermittlung entbindet der Kunde die beteiligten Banken gegenüber dem
                    Kreditvermittler gem § 38 Abs 2 Z 5 BWG vom Bankgeheimnis.
                </p>
                <h3>
                    <strong>§ 5 Dauer des Auftrages; Erfolg</strong>
                </h3>
                <p>
                    Die Kreditvermittlung ist dann erfolgreich, wenn eine Kreditzusage innerhalb von 60 Tagen nach
                    Vorlage aller Unterlagen an den Kunden übermittelt wird. Der Kunde verpflichtet sich, während des
                    aufrechten Vermittlungsauftrages den Kreditvermittler über zusätzliche Kreditanfragen im Voraus zu
                    informieren.
                </p>
                <h3>
                    <strong>§ 6 Entgelte</strong>
                </h3>
                <p>
                    Grundsätzlich erhält der Kreditvermittler vom Kreditgeber eine Provision, die sein Tätigwerden
                    honoriert. Der Kunde schuldet dem Kreditvermittler nur dann ein Entgelt für dessen Tätigkeit, wenn
                    dies vor Abschluss des Kreditvertrages auf Papier oder einem anderen dauerhaften Datenträger
                    vereinbart worden ist. Es gelten dabei primär die Bestimmungen des zwischen dem Kunden und dem
                    Kreditvermittler abgeschlossenen Kreditvermittlungsauftrags und subsidiär die gesetzlichen
                    Regelungen.
                </p>
                <h3>
                    <strong>§ 7 Informationspflichten des Kreditvermittlers</strong>
                </h3>
                <p>
                    Den Kreditvermittler trifft gegenüber dem Kunden eine Reihe von Informationspflichten. Um diesen
                    Informationspflichten nachzukommen, wird der Kreditvermittler dem Kunden Informationsmaterial
                    übermitteln. Der Kunde verpflichtet sich, dieses Informationsmaterial aufmerksam zu lesen. Der Kunde
                    verpflichtet sich weiters, erst dann eine Entscheidung zu treffen, wenn er die vom Kreditvermittler
                    zur Verfügung gestellten Informationen zur Kenntnis genommen hat.
                </p>
                <h3>
                    <strong>§ 8 Umschuldungen</strong>
                </h3>
                <p>
                    Der Kunde nimmt zur Kenntnis, dass es dem Kreditvermittler aufgrund seiner Standesregeln verboten
                    ist, im Zuge einer Umschuldung Kredite anzubieten oder zu vermitteln, bei denen der effektive
                    Jahreszinssatz gegenüber dem effektiven Zinssatz des abzulösenden Kredits bei Einrechnung der
                    Provision eine monatliche wirtschaftliche Mehrbelastung für den Kunden bedeuten würde.
                </p>
                <p>
                    Eine Änderung des Risikos (zB Zins oder Währungsrisiko) oder der Sicherheiten kann eine
                    wirtschaftliche Belastung oder Entlastung für den Kunden darstellen.
                </p>
                <p>
                    Droht dem Kunden die Zahlungsunfähigkeit, so wird dem Kunden das Aufsuchen einer staatlich
                    anerkannten Schuldnerberatungsstelle empfohlen.
                </p>
                <h3>
                    <strong>§ 9 Besondere Risiken bei Krediten mit Tilgungsträger</strong>
                </h3>
                <p>
                    Ein Kredit mit Tilgungsträger ist ein Kredit, bei dem die Zahlungen des Kunden zunächst nicht der
                    Tilgung des Kreditbetrags, sondern der Bildung von Kapital auf einem Tilgungsträger dienen und
                    vorgesehen ist, dass der Kredit später zumindest teilweise mit Hilfe des Tilgungsträgers
                    zurückgezahlt wird. Tilgungsträger können Wertpapiere, Kapitallebensversicherungen oder sonstige
                    Finanzprodukte sein.
                </p>
                <p>
                    Bei Krediten mit Tilgungsträger besteht insbesondere das Risiko, dass die Entwicklung des
                    Tilgungsträgers nicht ausreicht, um den Kredit wie geplant mit Hilfe des Tilgungsträgers
                    zurückzuzahlen. Um dieses Risiko zu verdeutlichen, wird der Kreditvermittler dem Kunden zusätzliche
                    Informationen übermitteln. Der Kunde verpflichtet sich, diese Risikoinformationen aufmerksam zu
                    lesen und erst dann eine Entscheidung zu treffen, wenn er diese Risikoinformationen zur Kenntnis
                    genommen hat.
                </p>
                <h3>
                    <strong>§ 10 Besondere Risiken bei Fremdwährungskrediten</strong>
                </h3>
                <p>
                    Ein Fremdwährungskredit ist ein Kreditvertrag, bei dem der Kredit auf eine andere Währung lautet als
                    die, in der der Verbraucher sein Einkommen bezieht oder die Vermögenswerte hält, aus denen der
                    Kredit zurückgezahlt werden soll, oder auf eine andere Währung als die Währung des Mitgliedstaats
                    lautet, in welchem der Verbraucher seinen Wohnsitz hat.
                </p>
                <p>
                    Bei einem Fremdwährungskredit besteht insbesondere das Risiko, dass Schwankungen des Wechselkurses
                    und / oder des Zinssatzes zu einer erhöhten Belastung des Kreditnehmers führen. Um dieses Risiko zu
                    verdeutlichen, wird der Kreditvermittler dem Kunden zusätzliche Informationen übermitteln. Der Kunde
                    verpflichtet sich, diese Risikoinformationen aufmerksam zu lesen und erst dann eine Entscheidung zu
                    treffen, wenn er diese Risikoinformationen zur Kenntnis genommen hat.
                </p>
                <h3>
                    <strong>§11 Gesetzliche Widerrufsbelehrung</strong>
                </h3>
                <p>Widerrufsrecht</p>
                <p>
                    Die Nutzer haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
                    widerrufen.
                </p>
                <p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.</p>
                <p>
                    Um das Widerrufsrecht auszuüben, müssen die Nutzer den Betreiber [Miracl GmbH, Lindengasse 26/1/5,
                    1070 Wien und, <em>kontakt@miracl.at</em>], mittels einer eindeutigen Erklärung (z.B. ein mit der
                    Post versandter Brief, Telefax oder E-Mail) über den Entschluss, diesen Vertrag zu widerrufen,
                    informieren. Die Nutzer können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch
                    nicht vorgeschrieben ist.
                </p>
                <p>
                    Zur Wahrung der Widerrufsfrist reicht es aus, dass die Nutzer die Mitteilung über die Ausübung des
                    Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                </p>
                <p>Folgen des Widerrufs</p>
                <p>
                    Wenn die Nutzer diesen Vertrag widerrufen, wird der Betreiber den Nutzer-Account und die zur
                    Verfügung gestellte Daten umgehend löschen.
                </p>
                <p>Muster-Widerrufsformular:</p>
                <p>
                    Wenn die Nutzer den Vertrag widerrufen wollen, dann können sie auch dieses Formular ausfüllen und
                    dem Betreiber senden:
                </p>
                <p>
                    An Miracl GmbH, Lindengasse 26/1/5, 1070 Wien, [
                    <a href="mailto:kontakt@miracl.at">kontakt@miracl.at</a>]:
                </p>
                <p>
                    Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über die Erbringung der
                    folgenden Dienstleistung: <strong>Kreditvermittlung</strong>
                </p>
                <p>Registriert am (*)</p>
                <p>Name des/der Verbraucher(s)</p>
                <p>Anschrift des/der Verbraucher(s)</p>
                <p>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
                <p>Datum</p>
                <p> (*) Unzutreffendes streichen.</p>
                <h3>
                    <strong>§ 12 Beschwerden</strong>
                </h3>
                <p>
                    Bei Beschwerden besteht die Möglichkeit, die Ombudsstelle des Fachverbands Finanzdienstleister in
                    Anspruch zu nehmen. Diese ist per E-Mail unter fdl.ombudsstelle@wko.at erreichbar.
                </p>
                <p>
                    Darüber hinaus besteht die Möglichkeit der alternativen Streitbeilegung durch das FIN-NET (
                    <a href="https://www.verbraucherschlichtung.or.at/">
                        <u>https://www.bankenschlichtung.at/</u>
                    </a>
                    ) oder die Schlichtung für Verbrauchergeschäfte (
                    <a href="https://www.verbraucherschlichtung.or.at/">
                        <u>https://www.verbraucherschlichtung.or.at/</u>
                    </a>
                    ).
                </p>
                <h2>
                    <strong>Anlage B – Leistungsumfang Rechtsanwalt</strong>
                </h2>
                <p>
                    <em>
                        Inkludiert ist die Vertragserrichtung und Abwicklung der Immobilientransaktion durch eine
                        Rechtsanwaltskanzlei. Dies beinhaltet
                    </em>
                </p>
                <ul>
                    <li>
                        <em>die Erstellung eines individuell angepassten Kaufvertrages;</em>
                    </li>
                    <li>
                        <em>
                            die Erläuterung mit den Vertragsparteien samt Abstimmung von Änderungswünschen bzw
                            Beantwortung von Rückfragen im üblichen Ausmaß;
                        </em>
                    </li>
                    <li>
                        <em>den Abschluss einer Treuhandvereinbarung mit der finanzierenden Bank;</em>
                    </li>
                    <li>
                        <em>die Unterstützung bei der Einholung der Urkunden für die Lastenfreistellung;</em>
                    </li>
                    <li>
                        <em>
                            die Unterstützung bei der Beantragung einer Grundverkehrsbehördlichen Genehmigung bzw der
                            Einholung einer Negativbestätigung;
                        </em>
                    </li>
                    <li>
                        <em>
                            die Selbstberechnung des Kaufvertrages über Finanzonline samt Abfuhr der Grunderwerbsteuer
                            und grundbücherlichen Eintragungsgebühr;
                        </em>
                    </li>
                    <li>
                        <em>die grundbücherliche Durchführung;</em>
                    </li>
                    <li>
                        <em>
                            die Kaufpreisauszahlung samt allfälliger Lastenfreistellung sowie Abfuhr der
                            Immobilienertragsteuer (sofern anwendbar).
                        </em>
                    </li>
                </ul>
                <p>
                    <em>
                        Grundsätzlich sind die für die Lastenfreistellung erforderlichen grundbuchsfähigen Urkunden von
                        den Vertragsparteien auf deren Kosten beizuschaffen. Dies gilt auch für Anträge in Zusammenhang
                        mit grundverkehrsbehördlichen Genehmigungen bzw der Einholung von Negativbestätigungen. &nbsp;
                    </em>
                </p>
                <p>
                    <em>
                        Für die Berechnung der Immobilienertragsteuer gilt, dass einfache Berechnungen für Neu- und
                        Altvermögen umfasst sind sowie ebenfalls die Bearbeitung im Falle der Hauptwohnsitzbefreiung.
                        Komplexe Berechnungen beispielsweise im Falle des Vorliegens einer Herstellerbefreiungen oder
                        Ähnliches sind von einem vom und auf Kosten des Kunden ausgewählten Steuerberater vorzunehmen,
                        der eine Bestätigung über die Berechnung an die Vertragserrichterin übergibt.
                    </em>
                </p>
            </Article>
        </Layout>
    );
};

export default Agb;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer", "page.agb"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
